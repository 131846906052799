<template>
  <UserListItem
    clickable
    hide-state-badge-on-hover
    :user
    :show-state-badge="false"
    class="rounded-2xl bg-white p-3"
    @click="included ? emit('excludeUser') : emit('includeUser')"
    @keyup.space="included ? emit('excludeUser') : emit('includeUser')"
  >
    <template #postfix>
      <FormKit type="switch" v-model="_included" />
    </template>
  </UserListItem>
</template>

<script setup lang="ts">
export interface Props {
  included: boolean
  user: User
}

withDefaults(defineProps<Props>(), {  })
const emit = defineEmits<{
  includeUser: []
  excludeUser: []
}>()

const _included = computed({
  get() {
    return __props.included
  },
  set(val) {},
})
</script>
