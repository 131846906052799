<template>
  <div id="reader" class="prose w-full max-w-full" v-html="model" />
</template>

<script setup lang="ts">
export interface Props {
  modelValue: string

  readToValidate?: boolean
}

withDefaults(defineProps<Props>(), { readToValidate: false, })
const model = defineModel<Props['modelValue']>()
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

onMounted(() => {
  if (__props.readToValidate) emit('validityChange', false)
})

if (__props.readToValidate) {
  const { pageScroll } = usePageState()
  watch(pageScroll.arrivedState, () => {
    if (pageScroll.arrivedState.bottom) emit('validityChange', true)
  })
}
</script>
