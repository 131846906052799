<template>
  <AtroContent class="w-full gap-2" items="center" justify="center">
    <AtroContent
      v-for="(step, i) in steps"
      :key="i"
      class="w-26"
      justify="center"
    >
      <AtroContent
        class="space-y-2"
        direction="col"
        items="center"
        justify="center"
      >
        <div
          :class="[
            'relative rounded-full border p-2',
            {
              'after:absolute after:left-full after:top-1/2 after:ml-1 after:h-0.5 after:w-14':
                i < steps.length - 1,
              'bg-primary after:bg-primary': i < currentStepIndex || complete,
              'border-primary': i <= currentStepIndex || complete,
              'after:bg-atro-warm-gray': i >= currentStepIndex,
            },
          ]"
        >
          <AtroIcon
            :class="[
              'size-[30px]',
              i < currentStepIndex || complete
                ? 'text-white'
                : 'text-atro-gray-2',
            ]"
            :name="i < currentStepIndex || complete ? 'check' : step.icon"
          />
        </div>
        <AtroSpan class="text-xs text-atro-gray" :text="step.label" />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  complete?: boolean
  currentStep?: string
  steps: { icon: IconName; label: string }[]
}

withDefaults(defineProps<Props>(), {  })

const currentStepIndex = computed(() =>
  __props.currentStep ? __props.steps.findIndex((step) => step.label === __props.currentStep) : -1,
)
</script>
