<template>
  <VueDatePicker
    auto-apply
    v-model="date"
    :teleport="true"
    :clearable="false"
    :input-class-name="
      [formkitTheme.global.input, formkitTheme['family:text'].input].join(' ')
    "
    :enable-time-picker="false"
    v-bind="context.attrs.props"
  />
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import { theme as formkitTheme } from '@atro/formkit-config'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export interface Props {
  context: FormKitFrameworkContext
}

withDefaults(defineProps<Props>(), {  })

const date = computed({
  get() {
    return __props.context._value
  },
  set(value: Date) {
    __props.context.node.input(value)
    __props.context.node.emit('change', value)
  },
})
</script>

<style lang="postcss">
:root {
  --dp-border-radius: theme('borderRadius.xl');
  --dp-cell-border-radius: 100%;
  --dp-cell-padding: theme('spacing.4');
  --dp-cell-size: theme('spacing.10');
  --dp-font-family: 'Inter', sans-serif;
  --dp-input-icon-padding: theme('spacing[1.5]');
  --dp-menu-padding: theme('spacing.4');
  --dp-row-maring: 0px;
}

.dp--menu-wrapper {
  @apply z-max;
}

.dp__input {
  @apply border-none px-4 py-2.5;
}

.dp__theme_light {
  --dp-text-color: theme('colors.atro.gray.1');
  --dp-primary-color: theme('colors.atro.purple.DEFAULT');
}

.dp__input_icon {
  @apply left-auto right-0;
}

.dp__calendar_header_item,
.dp__month_year_select {
  @apply text-atro-slate-purple;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_item {
  @apply m-1;
}
</style>
