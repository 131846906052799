<template>
  <AtroBadge capitalize color="warmGray" :label="category">
    <template #prefix>
      <AtroIcon class="mr-1.5 size-5 sm:-ml-2" :name="categoryIcon" />
    </template>
    <template #postfix>
      <AtroContent
        items="center"
        justify="center"
        :class="[
          '-mr-2 ml-4 size-6 rounded-full text-white',
          { 'bg-atro-red': count, 'bg-atro-green': !count },
        ]"
      >
        <AtroSpan v-if="count" semibold :text="count.toString()" />
        <AtroIcon v-else class="size-4" name="check" />
      </AtroContent>
    </template>
  </AtroBadge>
</template>

<script setup lang="ts">
export interface Props {
  category: RiskCategory
  count?: number
}

withDefaults(defineProps<Props>(), { count: 0, })

const categoryIcon = computed<IconName>(() => {
  switch (__props.category) {
    case 'cloud':
      return 'cloud'
    case 'devices':
      return 'devices-monotone'
    case 'networks':
      return 'network-wired'
    case 'people':
      return 'users'
    case 'software':
      return 'download'
  }
})
</script>
