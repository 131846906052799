<template>
  <AtroTagCloud
    v-model="model"
    v-bind="context.attrs.props"
    :options="context.attrs.options"
  />
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

withDefaults(defineProps<Props>(), {  })

const model = computed({
  get() {
    return __props.context._value
  },
  set(value) {
    __props.context.node.input(value)
  },
})
</script>
