<template>
  <AtroContent
    :class="[
      'space-x-1.5 rounded-lg p-2',
      {
        'bg-atro-warm-gray-2': variant === 'dark',
        'bg-white/15': variant === 'light',
      },
    ]"
    items="center"
    wrap="nowrap"
  >
    <AtroIcon
      v-if="status !== 'neutral'"
      :class="['size-4', statusColor]"
      :name="statusIcon"
    />
    <AtroSpan
      :class="{
        'text-atro-gray': variant === 'dark',
        'text-white': variant === 'light',
      }"
      :text
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface StatusItemProps {
  status: 'neutral' | 'ok' | 'warning'
  text: string

  variant?: 'dark' | 'light'
}

withDefaults(defineProps<StatusItemProps>(), { variant: 'dark', })

const statusColor = computed(() =>
  __props.status === 'ok' ? 'text-atro-green' : 'text-atro-red',
)
const statusIcon = computed<IconName>(() =>
  __props.status === 'ok' ? 'circle-check-outline' : 'xmark',
)
</script>
