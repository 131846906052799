<template>
  <AtroContent class="w-full" direction="col" wrap="nowrap">
    <AtroContent class="space-x-6">
      <AtroContent
        v-for="(tab, i) in tabs"
        tabbable
        :class="[
          'focus-visible:border-primary-75 group cursor-pointer space-x-1 border-b-2 pb-2 transition-all',
          selectedTabIndex === i
            ? 'border-primary text-primary'
            : 'text-primary-75 hover:border-primary-75 border-transparent',
        ]"
        :key="tab.label"
        items="center"
        justify="center"
        @click="onSelectTab(i)"
        @keyup.space="onSelectTab(i)"
      >
        <NuxtLink class="contents" :to="getTabDeepLink(i)">
          <AtroIcon v-if="tab.icon" class="mr-1 h-3 w-3" :name="tab.icon" />
          <AtroSpan semibold :text="tab.label" />
          <AtroContent
            v-if="tab.count"
            class="bg-primary/5 h-4 w-4 rounded-full"
            items="center"
            justify="center"
          >
            <AtroSpan semibold class="leading-3" size="xxs" :text="tab.count" />
          </AtroContent>
        </NuxtLink>
      </AtroContent>
    </AtroContent>
    <AtroContent v-auto-animate class="mt-4 w-full">
      <FlowComponent
        v-for="component in selectedTab.components"
        v-bind="component"
        :flow
        :key="`${selectedTab.label}-${component.name}`"
        :data="flow.data"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
type Tab = {
  components: FlowComponent[]
  label: string

  count?: string
  icon?: IconName
}

export interface Props {
  flow: Flow
  tabs: Tab[]

  deepLink?: boolean
}

withDefaults(defineProps<Props>(), {  })

const route = useRoute()

const _selectedTabIndex = ref(0)
const selectedTabIndex = computed({
  get() {
    return __props.deepLink ? Number(route.query?.tab || 0) : _selectedTabIndex.value
  },
  set(val: number) {
    _selectedTabIndex.value = val
  },
})
const selectedTab = computed<Tab>(() => __props.tabs[selectedTabIndex.value])

function onSelectTab(index: number) {
  selectedTabIndex.value = index
}

function getTabDeepLink(index: number) {
  return __props.deepLink
    ? { ...route, query: { ...route.query, tab: index } }
    : undefined
}
</script>
