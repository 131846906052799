<template>
  <AtroCard class="border-2" size="full">
    <AtroContent class="w-full space-x-6">
      <AtroContent class="flex-1" direction="col">
        <AtroHeading
          semibold
          class="mb-1 text-atro-slate-purple"
          size="xl"
          :text="content.title"
        />
        <AtroParagraph
          semibold
          class="mb-4 text-atro-gray-2"
          :text="content.subtitle"
          size="lg"
        />

        <AtroSpan
          semibold
          class="mb-1 text-atro-slate-purple"
          size="sm"
          text="Example Story"
        />
        <AtroParagraph class="text-atro-gray" :text="content.example" />
      </AtroContent>

      <NuxtImg
        :height="188"
        :width="277"
        :src="`/assessment/attack-likelihoods/${contentKey}.svg`"
      />
    </AtroContent>

    <AtroContent class="mt-6 w-full space-x-4" wrap="nowrap" items="strech">
      <AtroCard class="!w-auto flex-1 !flex-row space-x-8 !bg-atro-warm-gray-2">
        <RiskGaugeCard :risk-level />
        <AtroContent class="space-y-2" direction="col">
          <AtroSpan
            semibold
            class="text-atro-slate-purple"
            size="sm"
            text="Your Risk factors"
          />
          <AtroContent
            v-for="riskFactor in content.riskFactors"
            :key="riskFactor"
            class="space-x-1 rounded-xl bg-atro-red-15 p-2"
            wrap="nowrap"
            items="center"
          >
            <AtroIcon
              class="size-4 text-atro-red"
              name="circle-xmark-outline"
            />
            <AtroSpan class="text-atro-red" :text="riskFactor" />
          </AtroContent>
        </AtroContent>

        <AtroContent shrink class="space-y-2" direction="col">
          <AtroSpan
            semibold
            class="text-atro-slate-purple"
            size="sm"
            text="Attack Categories"
          />
          <AtroSpan
            v-for="attackCategory in content.attackCategories"
            :key="attackCategory"
            class="space-x-1 rounded-xl bg-white p-2 text-atro-gray"
            :text="attackCategory"
          />
        </AtroContent>
      </AtroCard>

      <AtroCard class="!w-[288px] !bg-atro-red-15">
        <AtroContent class="mb-2 w-full" justify="between">
          <AtroHeading
            semibold
            class="!text-[48px] !leading-10 text-atro-red"
            size="xxl"
            :text="`${content.infoPercent.toString()}%`"
          />
          <div class="rounded-full bg-white p-2 text-atro-red">
            <AtroIcon class="size-4" name="triangle-exclamation" />
          </div>
        </AtroContent>

        <AtroParagraph class="text-atro-slate-purple" size="sm">
          <AtroMarkdown :markdown="content.infoText" />
        </AtroParagraph>
      </AtroCard>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
export interface Props {
  contentKey: AttackLikelihoodKey
  riskLevel: RiskLevel
}

withDefaults(defineProps<Props>(), {  })

const content = computed(() => {
  switch (__props.contentKey) {
    case 'data_breaches':
      return {
        title: 'Data Breaches',
        subtitle:
          'Data breaches expose sensitive information, risking privacy and security',
        example:
          'Lisa, an HR manager, accidentally emailed employee payroll information to a phishing address, causing a data breach. This exposed personal details of hundreds of employees, leading to identity theft concerns and a loss of trust within the company.',
        infoPercent: 40,
        infoText:
          'of small businesses experienced critical data loss during a cyberattack, and among those affected, 39% lost customer data, severely impacting their operations and customer trust',
        riskFactors: ['Cloud Security', 'Phishing Tests', 'Policies'],
        attackCategories: [
          'Phishing Attacks',
          'Insider Threats',
          'Unpatched Software Vulnerabilities',
        ],
      }
    case 'financial_loss':
      return {
        title: 'Financial Loss',
        subtitle:
          'Financial loss from a cyberattack depletes company resources and revenue',
        example:
          "Sarah, a finance manager at a small company, unknowingly paid a fraudulent invoice sent by hackers posing as a trusted vendor. This mistake resulted in a significant financial loss and exposed the company's vulnerability to cyberattacks.",
        infoPercent: 60,
        infoText:
          'of small businesses that experience a cyberattack go out of business within six months due to the financial losses incurred.',
        riskFactors: ['Device Security', 'Phishing Tests', 'Cyber Insurance'],
        attackCategories: [
          'Ransomware Attacks',
          'Business Email Compromise (BEC)',
          'Data Breaches',
        ],
      }
    case 'intellectual_property_theft':
      return {
        title: 'Intellectual Property Theft',
        subtitle:
          'Intellectual property theft from a cyberattack compromises sensitive business innovations',
        example:
          "Hackers breached Innovatech's servers and stole their latest product designs, leading to intellectual property theft. This allowed competitors to copy their innovations, causing significant financial and competitive losses for the company.",
        infoPercent: 29,
        infoText:
          'nearly one-third of small businesses have experienced a cyberattack involving the theft of intellectual property, leading to significant financial losses and competitive disadvantages',
        riskFactors: ['Device Security', 'VPN', 'Phishing Tests'],
        attackCategories: [
          'Corporate Espionage',
          'Insider Threats',
          'Phishing Attacks',
        ],
      }
    case 'legal_consequences':
      return {
        title: 'Legal Consequences',
        subtitle:
          'Legal consequences from a cyberattack involve fines, lawsuits, and compliance violations',
        example:
          'When hackers stole customer data from E-Shop Inc., the company faced numerous lawsuits and hefty fines for failing to protect sensitive information. These legal consequences strained their finances and tarnished their reputation further.',
        infoPercent: 25,
        infoText:
          'of small businesses that suffer a cyberattack face legal action as a result, leading to significant financial strain from legal fees and settlements​',
        riskFactors: ['Policies', 'Cyber Insurance', 'Awareness Training'],
        attackCategories: [
          'Data Breaches',
          'Non-compliance with Regulations',
          'Intellectual Property Theft',
        ],
      }
    case 'operational_disruptions':
      return {
        title: 'Operational Disruptions',
        subtitle:
          'Operational disruption from a cyberattack halts business processes and productivity',
        example:
          "Hackers infiltrated ABC Manufacturing's network, crippling their production line by locking critical systems with ransomware. This operational disruption caused significant delays and financial losses, halting business for weeks.",
        infoPercent: 58,
        infoText:
          'of small businesses experience significant downtime following a cyberattack, with 40% experiencing at least eight hours of downtime, severely affecting their productivity and revenue',
        riskFactors: ['Cloud Security', 'Device Security', 'VPN'],
        attackCategories: [
          'Ransomware Attacks',
          'Distributed Denial of Service (DDoS) Attacks',
          'Malware Infections',
        ],
      }
    case 'reputation_damage':
      return {
        title: 'Reputation Damage',
        subtitle:
          'Reputational damage from a cyberattack erodes trust and loyalty quickly',
        example:
          "Hackers breached XYZ Corp's poorly secured database, extracting and leaking customer data online. This security failure led to a massive repetitional hit as customers quickly lost trust in the company's data protection capabilities.",
        infoPercent: 33,
        infoText:
          'one-third of small businesses lose customers following a cyberattack because of the resulting repetitional damage and loss of trust',
        riskFactors: ['Domain Security', 'Policies', 'Incident Response Plan'],
        attackCategories: [
          'Data Breaches',
          'Ransomware Attacks',
          'Publicized Security Vulnerabilities',
        ],
      }
    default:
      return {
        title: '',
        subtitle: '',
        example: '',
        infoPercent: 0,
        infoText: '',
        riskFactors: [],
        attackCategories: [],
      }
  }
})
</script>
