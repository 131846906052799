<template>
  <AtroContent class="relative" direction="col" items="center">
    <!-- TITLE -->
    <AtroSpan
      v-if="title"
      semibold
      class="mb-1 text-atro-slate-purple"
      size="sm"
      :text="title"
    />

    <!-- HORIZONTAL LABEL  -->
    <AtroSpan
      v-if="xLabel"
      class="absolute -bottom-5 left-1/2 -translate-x-1/2 uppercase text-atro-gray"
      size="xxs"
      :text="xLabel"
    />
    <!-- VERTICAL LABEL  -->
    <AtroSpan
      v-if="yLabel"
      class="absolute -left-8 top-24 -rotate-90 uppercase text-atro-gray"
      size="xxs"
      :text="yLabel"
    />

    <!-- BLOCKS -->
    <AtroContent class="size-[158px] gap-0.5 rounded-lg border p-1">
      <AtroContent
        v-for="(dataPoint, i) in data"
        :key="i"
        items="center"
        justify="center"
        :class="[
          'size-12 rounded-[4px]',
          {
            'bg-atro-yellow': i === 0 || i === 4 || i === 8,
            'bg-atro-orange': i === 1 || i === 5,
            'bg-atro-red': i === 2,
            'bg-atro-blue-50': i === 3 || i === 7,
            'bg-atro-blue': i === 6,
          },
        ]"
      >
        <AtroContent
          v-if="dataPoint"
          :class="[
            'size-[26px]',
            {
              'p-0': dataPoint > maxDataPoint * 0.66,
              'p-0.5':
                dataPoint < maxDataPoint * 0.66 &&
                dataPoint > maxDataPoint * 0.33,
              'p-1': dataPoint < maxDataPoint * 0.33,
            },
          ]"
        >
          <AtroContent
            :class="['size-full rounded-full border-transparent bg-white/75']"
            items="center"
            justify="center"
          >
            <AtroSpan
              semibold
              :class="[
                '!leading-none',
                {
                  'text-atro-yellow': i === 0 || i === 4 || i === 8,
                  'text-atro-orange': i === 1 || i === 5,
                  'text-atro-red': i === 2,
                  'text-atro-blue-50': i === 3 || i === 7,
                  'text-atro-blue': i === 6,
                },
              ]"
              :text="dataPoint.toString()"
            />
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  data: number[]
  title?: string
  xLabel?: string
  yLabel?: string
}

withDefaults(defineProps<Props>(), {  })

const maxDataPoint = computed(() => Math.max.apply(Math, __props.data))
</script>
