<template>
  <AtroContent
    items="center"
    justify="between"
    wrap="nowrap"
    :class="[
      'group relative w-full',
      {
        'cursor-pointer focus-visible:ring-2 active:bg-atro-warm-gray group-hover:ring-2 group-hover:ring-atro-warm-gray focus-visible:group-hover:ring-atro-focus-light':
          isClickable,
        'opacity-25': disabled,
      },
    ]"
    :tabbable="clickable"
  >
    <AtroContent
      shrink
      class="w-full space-x-2"
      items="center"
      justify="between"
    >
      <UserDisplay
        :user
        size="sm"
        :profile-modal-enabled
        :show-email-on-hover="showEmail"
      />
      <AtroContent
        v-if="showStateBadge"
        :class="{
          'transition-opacity group-hover:opacity-0 group-hover:delay-100':
            hideStateBadgeOnHover,
        }"
      >
        <AtroBadge v-if="isUserInvited" label="Invited" size="sm" />
        <AtroBadge v-else-if="isNonUser" label="Non-user" size="sm" />
      </AtroContent>
    </AtroContent>

    <slot name="postfix" />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  user: User

  clickable?: boolean
  disabled?: boolean
  hideStateBadgeOnHover?: boolean
  profileModalEnabled?: boolean
  showEmail?: boolean
  showStateBadge?: boolean
}

withDefaults(defineProps<Props>(), { profileModalEnabled: true,showStateBadge: true, })

const isClickable = computed(() => __props.clickable && !__props.disabled)
const isUserInvited = computed(() => !__props.user.joined && __props.user.id)
const isNonUser = computed(() => !__props.user.joined && !__props.user.id)
</script>
