import html2canvas from 'html2canvas'
import { toCamelCase, toSnakeCase } from 'js-convert-case'
import type { ModalName } from './useModal'

type PayloadConfig = {
  actionSlug: FlowActionType
  body?: any
  confirm?: boolean
}

export default function useFlowActions(
  flow: Ref<Flow>,
  users: Ref<User[]>,
  config: {
    afterAction?: (
      type: FlowActionType,
      payload: any,
      actionReturn?: any,
    ) => any
    beforeAction?: (
      type: FlowActionType,
      payload: any,
    ) => boolean | Promise<boolean>
    externalActionHandlers?: Record<
      FlowActionType,
      (payload: any, page?: Page) => any
    >
    refreshData?: () => void
    view?: 'flow' | 'orgFlow' | 'userFlow'
  } = {},
) {
  const { mutate: createOnboardFlow } =
    useMutation<OnboardingFlowResponse>('createOnboardFlow')
  const { mutate: createOrgFlow } = useMutation<OrgFlow>('createOrgFlow')
  const { mutate: saveOrgFlowData } = useMutation<OrgFlow>('saveOrgFlowData')
  const { mutate: saveUserFlowData } = useMutation<UserFlow>('saveUserFlowData')
  const { mutate: sendOrgFlowAction } = useMutation('sendOrgFlowAction')
  const { mutate: sendUserFlowAction } = useMutation('sendUserFlowAction')

  const connectIntegration = useIntegrationConnect()
  const copyText = useCopyText()
  const downloadPDF = useDownloadPDF()
  const globalState = useGlobalState()
  const router = useRouter()
  const sentry = useSentry()
  const toast = useToast()
  const { analytics } = useAnalytics()
  const { confirmAction } = useActionConfirm()
  const { getSession, signIn } = useAuth()
  const { confirmValidSubscriptionPlan, triggerPaywall } = useFlowPaywall(
    flow.value,
  )
  const { openMarketplace } = useMarketplace()
  const { openModal } = useModal()
  const { clearDirty } = usePageDirtyTracking()
  const { pageForm, pending } = usePageState()
  const { wrapWithPending, withPending } = usePendingWrap(pending)

  const partnerSlug = computed(() => router.currentRoute.value.query.partner)

  function afterModalClose() {
    removeQueryParam(router.currentRoute.value, ['action', 'payload'])
    config.refreshData && config.refreshData()
  }

  function findPage(pages: Page[], slug: string) {
    return pages.find((p) => p.slug === slug)
  }

  function handleAPIError(e: any) {
    pageForm.value && handleFormError(e, pageForm.value)
    // paywall trigger
    switch (e.statusCode) {
      case 402:
        triggerPaywall()
        break
      case 500:
        toast.error('An error occured while saving. Try again')
        break
      default:
        break
    }
  }

  const onCreateOrgFlow = wrapWithPending(async function () {
    try {
      const data = await createOrgFlow({ flowId: flow.value.slug })
      await navigateToWithOrg(`/org-flows/${data.id}/setup`, { replace: true })
    } catch (e) {
      handleAPIError(e)
    }
  })

  const onCreateOnboardFlow = wrapWithPending(async function () {
    const dataToPost = flow.value.data.infoFields as Record<string, any>
    if (partnerSlug.value) {
      dataToPost.org.partner = partnerSlug
    }
    try {
      const data = await createOnboardFlow({
        orgFlow: { flowId: flow.value.id },
        ...dataToPost,
      })
      await signIn('credentials', {
        callbackUrl: '/',
        redirect: false,
        type: 'claimToken',
        data: JSON.stringify(data.auth),
      })
      const updatedSession = await getSession()
      await navigateTo(
        {
          path: `/orgs/${updatedSession?.orgs[0].slug}/org-flows/${data.orgFlow.id}/setup`,
          query: { onboarding: 'true' },
        },
        { replace: true },
      )
    } catch (e) {
      handleAPIError(e)
    }
  })

  async function savePageData(page: Page) {
    let promise
    try {
      if (config.view === 'orgFlow') {
        promise = saveOrgFlowData(flow.value.data, {
          params: {
            id: flow.value.id,
          },
          query: {
            page: page.slug,
          },
        })
      } else if (config.view === 'userFlow') {
        promise = saveUserFlowData(flow.value.data, {
          params: { id: flow.value.id },
          query: {
            page: page.slug,
          },
        })
      }
      if (!page.async) {
        pending.value = true
        await promise
      } else {
        promise?.catch((e) => handleAPIError(e))
      }
    } catch (e) {
      handleAPIError(e)
      pending.value = false
      throw e
    }
    pending.value = false
  }

  function sendAction(payload: any, params?: any) {
    if (config.view === 'orgFlow') {
      return sendOrgFlowAction(payload, { params })
    } else if (config.view === 'userFlow') {
      return sendUserFlowAction(payload, { params })
    }
    return Promise.resolve()
  }

  const actionHandlers: Record<
    FlowActionType,
    (payload: any, page?: Page) => any
  > = {
    claim_account: () => navigateTo('/signup/connect'),
    complete: async (payload, page) => {
      clearDirty()
      const query = {
        ...router.currentRoute.value.query,
      }
      if (config.view === 'orgFlow') {
        confirmValidSubscriptionPlan(async () => {
          await savePageData(page as Page)
          // TODO: move to after action handler
          globalState.value.flowCompleted = true
          navigateToWithOrg(
            {
              query,
              path: `/org-flows/${flow.value.id}`,
            },
            { replace: true },
          )
        })
      } else if (config.view === 'userFlow') {
        await savePageData(page as Page)
        globalState.value.flowCompleted = true
        navigateToWithOrg(
          { query, path: `/user-flows/${flow.value.id}` },
          { replace: true },
        )
      }
    },
    copy_page_url: () => copyText(window.location.href, 'Share link copied!'),
    delete_item: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'delete_item',
        confirm: true,
      })
    },
    instantiate_onboard_flow: () => onCreateOnboardFlow(),
    instantiate_org_flow: () => onCreateOrgFlow(),
    internal_action: async (payload: PayloadConfig) => {
      if (payload.confirm) {
        confirmAction(
          payload.actionSlug,
          flow,
          wrapWithPending(async () => {
            try {
              await actionHandlers[payload.actionSlug]?.(payload.body)
              return true
            } catch (e) {
              handleAPIError(e)
              return false
            }
          }),
        )
      } else {
        try {
          await actionHandlers[payload.actionSlug]?.(payload.body)
        } catch (e) {
          handleAPIError(e)
        }
      }
    },
    next_page: () => {},
    notify: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'notify',
        confirm: true,
      })
    },
    notify_many: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'notify_many',
        confirm: true,
      })
    },
    oauth_connect: async (payload) => {
      try {
        await withPending(connectIntegration(payload as OAuthService))
      } catch (e: any) {
        const errorDetails = e.data?.errors?.detail
        if (e.message === 'cancelled') {
          toast.error('Connection cancelled')
          // Google connection will throw 403 when connecting a non-admin account
        } else if (e.statusCode === 403) {
          switch (errorDetails) {
            case 'license required':
              toast.error(
                'Invalid subscription type. Microsoft Entra ID P1 subscription may be required.',
              )
              break
            default:
              toast.error(
                'Connection failed! Account selected is not an administrator.',
              )
              break
          }
        } else {
          toast.error('Connection error!')
        }
        throw e
      }
    },
    open_link: (payload) => navigateTo(payload),
    open_marketplace: (payload) =>
      openMarketplace({ ...(payload ? { slug: payload } : {}) }),
    open_modal: (slug, parentPage) => {
      const pageToOpen = findPage(flow.value.pages, slug)
      if (pageToOpen) {
        openModal('flowPage', {
          eventHandlers: {
            action: (payload: [Page, FlowAction]) => {
              onAction(...payload)
            },
          },
          afterClose: afterModalClose,
          props: {
            flow,
            users,
            page: pageToOpen,
          },
        })
      } else {
        openModal(toCamelCase(slug) as ModalName, {
          eventHandlers: {
            action: (action: FlowAction) => {
              parentPage && onAction(parentPage, action)
            },
          },
          afterClose: afterModalClose,
          props: {
            flow,
            users,
          },
        })
      }
    },
    open_page: (payload) => {
      const pageToOpen = findPage(flow.value.pages, payload)
      if (pageToOpen) {
        if (config.view === 'orgFlow') {
          navigateToWithOrg(
            `/org-flows/${flow.value.id}/setup?page=${pageToOpen.slug}`,
          )
        } else {
          navigateToWithOrg(
            `/user-flows/${flow.value.id}/setup?page=${pageToOpen.slug}`,
          )
        }
      } else {
        console.warn(
          'MISSING PAGE SLUG:',
          payload,
          'AVAILABLE SLUGS:',
          flow.value.pages.map((page) => page.slug),
        )
      }
    },
    prev_page: () => {},
    refresh_connection: async (paylaod) => {
      try {
        await withPending(
          sendAction(null, {
            id: flow.value.id,
            actionSlug: 'fetch_data',
          }),
        )
        if (typeof paylaod === 'function') paylaod()
        toast.success(
          'Data refreshed. Depending on the service, updates may be delayed.',
        )
      } catch (e) {}
      return true
    },
    reset: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'reset',
        confirm: true,
      })
    },
    save_page_data: async (payload, page) => {
      await savePageData(page as Page)
    },
    screenshot: async (fileName = 'screenshot') => {
      const el = document.querySelector<HTMLElement>('#flow-main-content')
      if (el) {
        const padding = 96
        const elHeight = el.offsetHeight
        const cardHeight =
          document.querySelector<HTMLElement>('#flow-main-content-card')
            ?.offsetHeight || 0
        const height = Math.max(elHeight, cardHeight) + padding
        const width = el.offsetWidth + padding
        const elBoundingRect = el.getBoundingClientRect()
        const canvas = await html2canvas(el, {
          height,
          width,
          foreignObjectRendering: true,
          x: -(elBoundingRect.left + padding / 6),
          y: -(elBoundingRect.top + padding / 6),
        })
        const img = canvas.toDataURL('image/png')
        saveAs(img, `${fileName}.png`)
      }
    },
    send_action: async (payload: PayloadConfig) => {
      const send = async () => {
        return await sendAction(payload.body, {
          id: flow.value.id,
          actionSlug: payload.actionSlug,
        })
      }
      if (payload.confirm) {
        confirmAction(
          payload.actionSlug,
          flow,
          wrapWithPending(async () => {
            try {
              await send()
              return true
            } catch (e) {
              handleAPIError(e)
              return false
            }
          }),
        )
      } else {
        try {
          return await send()
        } catch (e) {
          handleAPIError(e)
        }
      }
    },
    set_autopilot: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'set_autopilot',
      })
    },
    set_public_page: (body) => {
      actionHandlers.send_action({
        body,
        actionSlug: 'set_public_page',
      })
    },
    submit: async (payload, page) => {
      await savePageData(page as Page)
    },
    update_assignees: (payload, page) => savePageData(page as Page),

    // UNIQUE FLOW ACTIONS
    // SECURITY POLICY
    download: () =>
      downloadPDF(
        toSnakeCase(flow.value.title),
        flow.value.data.policy as string,
      ),
    reset_policy: () => {
      confirmAction('reset_policy', flow, () => {
        flow.value.data = {
          ...flow.value.data,
          policyDraft: flow.value.data.policyTemplate,
        }
        return true
      })
    },
    share_policy: () =>
      copyText(
        `${window.location.origin}/policies/${flow.value.id}`,
        'Share link copied!',
      ),
  }

  async function onAction(actionPage: Page, action: FlowAction) {
    if (!action) return

    const actionName = toSnakeCase(action.type) as FlowActionType
    const handler =
      actionHandlers[actionName] || config.externalActionHandlers?.[actionName]

    // @ts-ignore
    if (handler) {
      console.log(
        'ACTION:',
        actionName,
        'PAYLOAD:',
        action.data,
        'actionPage',
        actionPage,
      )
      try {
        let beforeActionResponse = true
        if (config.beforeAction) {
          beforeActionResponse = await config.beforeAction(
            action.type,
            action.data,
          )
        }
        if (beforeActionResponse !== false) {
          const response = await handler(action.data, actionPage)
          config.afterAction &&
            config.afterAction(action.type, action.data, response)
          analytics.value?.track('flow-action', {
            actionType: action.type,
            page: actionPage.slug,
          })
        }
      } catch (e) {
        console.error('ACTION FAILED', e)
        sentry.captureException(e)
      }
    } else {
      console.warn('UNHANDLED ACTION: ', actionName)
    }
  }

  return onAction
}
