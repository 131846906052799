<template>
  <Motion
    :style
    class="fixed z-20 flex min-h-0 origin-center flex-nowrap items-center justify-center rounded-2xl bg-white shadow-card"
    :initial="cardInitial"
    :animate="cardAniamteTo"
    :transition="{
      delay: view === 'open' ? 0 : 0.2,
      duration: view === 'open' ? 0.1 : 0.5,
      easing:
        view === 'open' ? spring({ damping: 14, restSpeed: 150 }) : 'ease',
    }"
    @motioncomplete="endTransition"
  >
    <Transition
      appear
      enter-active-class="duration-1000 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <AtroContent
        v-if="view === 'close'"
        class="h-[var(--flow-card-height)] w-[var(--flow-card-width)] border-2 border-transparent px-4 py-2 text-center"
        direction="col"
        items="center"
      >
        <div class="mb-2.5 h-6 w-full" />
        <AtroIcon class="mb-3 size-10 text-black/45" :name="flow.icon" />
        <AtroParagraph
          semibold
          class="text-atro-slate-purple"
          size="sm-short"
          :text="flow.title"
        />
      </AtroContent>
    </Transition>

    <Transition
      appear
      enter-active-class="duration-300 delay-1000 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <AtroLoading v-if="view === 'open'" />
    </Transition>
  </Motion>
</template>

<script setup lang="ts">
import { spring } from '@oku-ui/motion'

export interface Props {
  cardRect: DOMRect
  flow: Flow
  view: 'open' | 'close'

  bottomToolbarActive?: boolean
}

withDefaults(defineProps<Props>(), {  })

const APP_BANNER_OFFSET = 48
const CONTENT_PADDING_TOP = 88
const CONTENT_PADDING_TOP_MOBILE = 84
const CONTENT_PADDING_X = 48
const CONTENT_PADDING_X_MOBILE = 8
const MAX_WIDOW_WIDTH = 1262
const SIDE_PANEL_WIDTH = 0

const isMobile = useIsMobile()
const globalState = useGlobalState()
const { endTransition } = useFlowCardTransition()
const { width: windowWidth, height: widowHeight } = useWindowSize()

const style = computed(() => {
  return {
    top: `${__props.cardRect.top}px`,
    left: `${__props.cardRect.left}px`,
  }
})

const cardInitial = computed(() => {
  if (__props.view === 'open') {
    return {
      borderRadius: '16px',
      padding: 'var(--flow-card-padding)',
      height: 'var(--flow-card-height)',
      width: 'var(--flow-card-width)',
    }
  } else {
    return {
      ...getOpenedConfig(),
      borderRadius: '24px',
      padding: isMobile.value ? '16px' : '48px',
    }
  }
})

const cardAniamteTo = computed(() => {
  if (__props.view === 'open') {
    return {
      ...getOpenedConfig(),
      borderRadius: '24px',
      padding: isMobile.value ? '16px' : '48px',
    }
  } else {
    return {
      borderRadius: '16px',
      padding: 'var(--flow-card-padding)',
      height: 'var(--flow-card-height)',
      width: 'var(--flow-card-width)',
      translateX: '0px',
      translateY: '-2px',
    }
  }
})

const appBannerOffset = computed(() =>
  globalState.value.appBannerShown ? APP_BANNER_OFFSET : 0,
)
const contentPaddingX = computed(() =>
  isMobile.value ? CONTENT_PADDING_X_MOBILE : CONTENT_PADDING_X,
)
const contentPaddingTop = computed(
  () =>
    (isMobile.value ? CONTENT_PADDING_TOP_MOBILE : CONTENT_PADDING_TOP) +
    appBannerOffset.value,
)
const contentPaddingBottom = computed(() =>
  isMobile.value
    ? __props.bottomToolbarActive
      ? 132
      : 20
    : __props.bottomToolbarActive
      ? 96
      : 24,
)

function getOpenedConfig() {
  const width = `${
    Math.min(MAX_WIDOW_WIDTH, windowWidth.value) -
    SIDE_PANEL_WIDTH -
    contentPaddingX.value * 2
  }px`
  const widthOffset = Math.max(0, windowWidth.value - MAX_WIDOW_WIDTH)
  const height = `${
    widowHeight.value - contentPaddingTop.value - contentPaddingBottom.value
  }px`
  const translateX = `${
    contentPaddingX.value + SIDE_PANEL_WIDTH - __props.cardRect.left + widthOffset / 2
  }px`
  const translateY = `${contentPaddingTop.value - __props.cardRect.top}px`

  return { height, width, translateX, translateY }
}
</script>
