<template>
  <AtroContent
    class="mb-2 w-full rounded-2xl bg-atro-warm-gray-2 p-3 sm:p-6"
    justify="between"
    wrap="nowrap"
    :direction="$isMobile.value ? 'col' : 'row'"
  >
    <AtroHeading
      v-if="title && $isMobile.value"
      semibold
      class="w-full text-atro-slate-purple"
      :text="title"
    />
    <AtroContent
      wrap="nowrap"
      :direction="$isMobile.value ? 'row' : 'col'"
      :shrink="!$isMobile.value"
    >
      <AtroHeading
        v-if="title && !$isMobile.value"
        semibold
        class="w-full text-atro-slate-purple"
        :text="title"
      />
      <AtroMarkdown
        v-if="body"
        class="mt-2 text-sm text-atro-gray-1"
        :markdown="body"
      />

      <FlowAtomButton
        v-if="button"
        class="mt-4"
        v-bind="button"
        @action="onAction"
      />
      <AtroContent v-if="icon && $isMobile.value" class="sm:pl-10">
        <AtroIcon :style="iconStyle" :name="icon" />
      </AtroContent>
    </AtroContent>

    <AtroContent v-if="icon && !$isMobile.value" class="sm:pl-10">
      <AtroIcon :style="iconStyle" :name="icon" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { Props as ButtonProps } from '@/components/flow/atom/FlowAtomButton.vue'

export interface Props {
  body?: string
  button?: ButtonProps
  icon?: IconName
  iconSize?: number | string
  title?: string
}
const DETAULT_ICON_SIZE = 200
withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const isMobile = useIsMobile()

const onAction = (action: FlowAction) => {
  emit('action', action)
}

const iconStyle = computed(() => {
  return isMobile.value
    ? { maxWidth: '100px' }
    : {
        height: `${__props.iconSize || DETAULT_ICON_SIZE}px`,
        width: `${__props.iconSize || DETAULT_ICON_SIZE}px`,
      }
})
</script>
