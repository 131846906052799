export function usePaymentCollection() {
  const toast = useToast()
  const { getSession } = useAuth()
  const { org } = useCurrentOrg()

  async function initiatePaymentCollection(stripeUrl: string) {
    const promise = new Promise<boolean>((resolve) => {
      let checkingSubscriptionStatus = false
      openPopup({
        url: stripeUrl,
        onPopupClosed: () =>
          window.removeEventListener('focus', checkSubscriptionStatus),
      })

      async function checkSubscriptionStatus() {
        if (!checkingSubscriptionStatus && org.value?.id) {
          checkingSubscriptionStatus = true
          try {
            await pollSubscriptionStatus(org.value.id)
            // updates the user's session with the new subscription plan
            await getSession()
            resolve(true)
          } catch (e) {
            resolve(false)
            toast.error(
              "We couldn't verify your subscription status. Please try again.",
            )
          }
        }
        checkingSubscriptionStatus = false
      }
      window.addEventListener('focus', checkSubscriptionStatus)
    })

    return promise
  }

  async function pollSubscriptionStatus(orgId: string) {
    let pollcount = 0

    return new Promise((resolve, reject) => {
      const pollInterval = setInterval(async () => {
        const currentOrg = await $fetch<Org>(`/api/orgs/${orgId}`)
        if (
          currentOrg.subscription?.plan &&
          currentOrg.subscription?.state === 'active' &&
          currentOrg.subscription?.remainingOrgFlows
        ) {
          clearInterval(pollInterval)
          resolve(true)
        }
        if (pollcount >= 10) {
          clearInterval(pollInterval)
          reject(new Error('timeout'))
        }
        pollcount += 1
      }, 500)
    })
  }

  return { initiatePaymentCollection }
}
