<template>
  <div class="w-full">
    <AtroMarkdown :prose :markdown="_markdown" v-bind="$attrs" />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  markdown?: string
  text?: string
  prose?: boolean
}

defineOptions({
  inheritAttrs: false,
})
withDefaults(defineProps<Props>(), { prose: true, })

const _markdown = computed(() => __props.markdown || __props.text || '')
</script>
