<template>
  <AtroContent class="w-full" :items="align" :justify="align">
    <div class="relative">
      <AtroIcon v-if="showIcon" :name="icon" :style="iconStyle" />

      <NuxtImg v-else-if="_imageUrl" :height :width :src="_imageUrl" />
      <!-- HOTSPOTS -->
      <div
        class="absolute z-1"
        v-for="hotspot in hotspots"
        :key="hotspot.tooltip"
        :style="{
          top: `${hotspot.pointer.y}%`,
          left: `${hotspot.pointer.x}%`,
        }"
      >
        <AtroPopover
          prevent-overflow
          :auto-hide="false"
          :popper-class="[
            'hotspot max-w-sm',
            hotspotsVisibility?.[hotspot.tooltip] && 'seen',
          ]"
          :distance="isMobile ? 0 : -10"
          :placement="isMobile ? 'bottom' : 'right'"
          :hide-triggers="['focus']"
          :show-triggers="['focus']"
          :popper-triggers="[]"
          @update:shown="
            (shown: boolean) => !shown && seenHotspot(hotspot.tooltip)
          "
        >
          <template #trigger="{ shown }">
            <div
              tabindex="1"
              :class="[
                'cursor-pointer overflow-hidden rounded-full p-1 text-white ring-2 drop-shadow-md transition-colors',
                { 'opacity-0': shown },
                { 'bg-primary': !hotspotsVisibility?.[hotspot.tooltip] },
                {
                  'seen bg-atro-green ring-atro-green':
                    hotspotsVisibility?.[hotspot.tooltip],
                },
              ]"
            >
              <AtroIcon
                class="h-4 w-4"
                :name="hotspotsVisibility?.[hotspot.tooltip] ? 'check' : 'plus'"
              />
              <div class="triangle-left" />
            </div>
          </template>

          <template #content>
            <AtroContent items="center">
              <AtroMarkdown
                class="text-atro-dark-purple"
                :markdown="hotspot.tooltip"
                @action="(action: FlowAction) => emit('action', action)"
              />
            </AtroContent>
          </template>
        </AtroPopover>
      </div>
    </div>
  </AtroContent>
</template>

<script setup lang="ts">
type ImageHotspot = {
  tooltip: string
  pointer: {
    x: number
    y: number
  }
}

export interface Props {
  height: number | string
  width: number | string

  align?: 'start' | 'center' | 'end'
  hotspots?: ImageHotspot[]
  hotspotsRequired?: boolean
  icon?: IconName
  imageUrl?: string
  parentOrgLogoSwitchout?: boolean
}

withDefaults(defineProps<Props>(), { align: 'center',height: 100,width: 100, })

const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()

const isMobile = useIsMobile()
const { org } = useCurrentOrg()

const hotspotsVisibility = ref(
  __props.hotspots?.reduce(
    (obj, hotspot) => {
      obj[hotspot.tooltip] = false
      return obj
    },
    {} as Record<string, boolean>,
  ),
)

const iconStyle = computed(() => ({
  height: `${__props.height}px`,
  width: `${__props.width}px`,
}))
const _imageUrl = computed(() =>
  __props.parentOrgLogoSwitchout && org.value?.parent?.partnerProfile?.logo
    ? org.value?.parent?.partnerProfile.logo
    : __props.imageUrl,
)
const showIcon = computed(() => !__props.parentOrgLogoSwitchout && __props.icon)

watchDeep(
  hotspotsVisibility,
  () => {
    if (__props.hotspotsRequired && hotspotsVisibility.value)
      emit(
        'validityChange',
        Object.values(hotspotsVisibility.value).every((beenSeen) => beenSeen),
      )
  },
  { immediate: true },
)

function seenHotspot(key: string) {
  setTimeout(() => {
    if (hotspotsVisibility.value) hotspotsVisibility.value[key] = true
  }, 200)
}
</script>

<style lang="postcss" scoped>
.triangle-left {
  border-width: 6px 8px 6px 0;
  border-color: transparent theme('colors.atro.purple.DEFAULT') transparent
    transparent;
  @apply absolute -left-[8px] top-1.5 h-0 w-0 border-solid;
}

.seen .triangle-left {
  @apply border-r-atro-green;
}
</style>

<style lang="postcss">
/* hotspot tooltip */
.hotspot.v-popper__popper.v-popper--theme-dropdown .v-popper__inner {
  @apply !rounded-xl !border-2 !border-primary !bg-white !px-4 !py-3 !shadow-card;
}
.hotspot.v-popper__popper.v-popper--theme-dropdown.seen .v-popper__inner {
  @apply !border-atro-green;
}

.hotspot.v-popper__popper.v-popper--theme-dropdown .v-popper__arrow-inner {
  @apply !border-[0px];
}
.hotspot.v-popper__popper.v-popper--theme-dropdown .v-popper__arrow-outer {
  @apply border-primary;
}
.hotspot.v-popper__popper.v-popper--theme-dropdown.seen .v-popper__arrow-outer {
  @apply border-atro-green;
}
</style>
