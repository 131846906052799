<template>
  <AtroContent class="w-full space-y-2" direction="col">
    <div>
      <AtroSpan v-if="title" class="text-atro-gray" :text="title" />
      <span v-if="required" class="field-is-required">*</span>
    </div>
    <AtroContent
      :class="[
        'w-full',
        { 'gap-2': gap === 'sm', 'gap-3': gap === 'md', 'gap-4': gap === 'lg' },
      ]"
    >
      <FlowComponent
        v-for="(config, i) in components"
        v-bind="config"
        :flow
        :key="i"
        :data="flow?.data"
        @action="onAction"
        @validity-change="(validity) => onValidityChange(validity, i)"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  components: FlowComponent[]

  flow?: Flow
  gap?: 'sm' | 'md' | 'lg'
  required?: boolean
  title?: string
}

withDefaults(defineProps<Props>(), { gap: 'md', })
const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()

const validityObj = ref(
  __props.components.reduce(
    (validObj, comp, i) => {
      validObj[i] = false
      return validObj
    },
    {} as Record<number, boolean>,
  ),
)

const onAction = (action: FlowAction) => {
  emit('action', action)
}
function onValidityChange(valid: boolean, index: number) {
  validityObj.value[index] = valid
}

watchDeep(
  validityObj,
  () => {
    __props.required &&
      emit(
        'validityChange',
        Object.values(validityObj.value).every((item) => item),
      )
  },
  { immediate: true },
)
</script>
