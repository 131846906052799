<template>
  <UserListItem
    clickable
    hide-state-badge-on-hover
    :disabled
    :user
    :profile-modal-enabled="false"
    class="rounded-2xl bg-atro-warm-gray-2 p-3"
    @click="onAction"
    @keyup.space="onAction"
  >
    <template #postfix v-if="!disabled">
      <AtroContent
        class="!absolute right-4 z-1 opacity-0 transition-opacity group-hover:opacity-100 group-hover:delay-100"
      >
        <AtroButton v-if="view === 'assigned'" type="flat">
          <AtroIcon class="h-4 w-4 text-primary" name="trash" />
        </AtroButton>
        <AtroButton v-else type="flat">
          <AtroIcon class="h-4 w-4 text-primary" name="plus" />
        </AtroButton>
      </AtroContent>
    </template>
  </UserListItem>
</template>

<script setup lang="ts">
export interface Props {
  user: User
  view: 'assigned' | 'unassigned'

  disabled?: boolean
}

withDefaults(defineProps<Props>(), {  })

const emit = defineEmits<{
  assignUser: [user: User]
  unassignUser: [user: User]
}>()

function onAction() {
  if (__props.disabled) return
  if (__props.view === 'assigned') {
    emit('unassignUser', __props.user)
  } else if (__props.view === 'unassigned') {
    emit('assignUser', __props.user)
  }
}
</script>
