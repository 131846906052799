<template>
  <AtroContent
    class="w-full"
    wrap="nowrap"
    :direction="direction"
    :justify="justify"
    :items="items"
  >
    <FlowComponent
      v-for="config in components"
      v-bind="config"
      :key="config.name"
      :data="flow.data"
      :flow
      :parent-org
      :users
      @action="onAction"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  components: FlowComponent[]
  flow: Flow

  parentOrg?: ParentOrg
  users?: User[]
  direction?: 'col' | 'row'
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  items?: 'start' | 'end' | 'center' | 'baseline' | 'strech'
}

withDefaults(defineProps<Props>(), { direction: 'col', })
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const onAction = (action: FlowAction) => {
  emit('action', action)
}
</script>
