import { default as iconsMzw4xMStLeMeta } from "/vercel/path0/pages/admin/icons.vue?macro=true";
import { default as orgsia8jowOJ3tMeta } from "/vercel/path0/pages/admin/orgs.vue?macro=true";
import { default as servicesGWQz7pAQ5UMeta } from "/vercel/path0/pages/admin/services.vue?macro=true";
import { default as _91service_93scfmiK1nBNMeta } from "/vercel/path0/pages/connect/[service].vue?macro=true";
import { default as _91id_937kGfPT4l3NMeta } from "/vercel/path0/pages/devices/[id].vue?macro=true";
import { default as handoffR8HsWsFOoFMeta } from "/vercel/path0/pages/devices/handoff.vue?macro=true";
import { default as new1GLKyv1EeCMeta } from "/vercel/path0/pages/devices/new.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as org_45switchRG90OKP7p7Meta } from "/vercel/path0/pages/org-switch.vue?macro=true";
import { default as org_45switcherevb9ewn2MUMeta } from "/vercel/path0/pages/org-switcher.vue?macro=true";
import { default as indexWWeg0dKyzZMeta } from "/vercel/path0/pages/orgs/[slug]/dashboard/index.vue?macro=true";
import { default as leadscg99ApG3xBMeta } from "/vercel/path0/pages/orgs/[slug]/dashboard/leads.vue?macro=true";
import { default as profilezCybHZkHvKMeta } from "/vercel/path0/pages/orgs/[slug]/dashboard/profile.vue?macro=true";
import { default as templatesiPW5AcjXOgMeta } from "/vercel/path0/pages/orgs/[slug]/dashboard/templates.vue?macro=true";
import { default as indexswt0H8YI0JMeta } from "/vercel/path0/pages/orgs/[slug]/index.vue?macro=true";
import { default as orgBOWADsw39yMeta } from "/vercel/path0/pages/orgs/[slug]/manage/org.vue?macro=true";
import { default as profilebrxzV1Rd3lMeta } from "/vercel/path0/pages/orgs/[slug]/manage/profile.vue?macro=true";
import { default as teamBplYnawIhbMeta } from "/vercel/path0/pages/orgs/[slug]/manage/team.vue?macro=true";
import { default as indexU2RJYWxNFAMeta } from "/vercel/path0/pages/orgs/[slug]/org-flows/[id]/index.vue?macro=true";
import { default as setuphwcbWVvJBkMeta } from "/vercel/path0/pages/orgs/[slug]/org-flows/[id]/setup.vue?macro=true";
import { default as indexnrd7f6rm6TMeta } from "/vercel/path0/pages/orgs/[slug]/score/index.vue?macro=true";
import { default as indexfTtngvLnYmMeta } from "/vercel/path0/pages/orgs/[slug]/team/index.vue?macro=true";
import { default as indexLmDWppGi1FMeta } from "/vercel/path0/pages/orgs/[slug]/user-flows/[id]/index.vue?macro=true";
import { default as setupvHYvopGzWMMeta } from "/vercel/path0/pages/orgs/[slug]/user-flows/[id]/setup.vue?macro=true";
import { default as welcomeoO631poOnTMeta } from "/vercel/path0/pages/orgs/[slug]/welcome.vue?macro=true";
import { default as _91id_93oZkwtOEN5jMeta } from "/vercel/path0/pages/policies/[id].vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as indexJJ7LgVnsavMeta } from "/vercel/path0/pages/security-assessment/index.vue?macro=true";
import { default as connectP5lMxj21ctMeta } from "/vercel/path0/pages/signup/connect.vue?macro=true";
import { default as indexLAWGSf2MvbMeta } from "/vercel/path0/pages/signup/index.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-icons",
    path: "/admin/icons",
    meta: iconsMzw4xMStLeMeta || {},
    component: () => import("/vercel/path0/pages/admin/icons.vue").then(m => m.default || m)
  },
  {
    name: "admin-orgs",
    path: "/admin/orgs",
    meta: orgsia8jowOJ3tMeta || {},
    component: () => import("/vercel/path0/pages/admin/orgs.vue").then(m => m.default || m)
  },
  {
    name: "admin-services",
    path: "/admin/services",
    meta: servicesGWQz7pAQ5UMeta || {},
    component: () => import("/vercel/path0/pages/admin/services.vue").then(m => m.default || m)
  },
  {
    name: "connect-service",
    path: "/connect/:service()",
    meta: _91service_93scfmiK1nBNMeta || {},
    component: () => import("/vercel/path0/pages/connect/[service].vue").then(m => m.default || m)
  },
  {
    name: "devices-id",
    path: "/devices/:id()",
    meta: _91id_937kGfPT4l3NMeta || {},
    component: () => import("/vercel/path0/pages/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: "devices-handoff",
    path: "/devices/handoff",
    meta: handoffR8HsWsFOoFMeta || {},
    component: () => import("/vercel/path0/pages/devices/handoff.vue").then(m => m.default || m)
  },
  {
    name: "devices-new",
    path: "/devices/new",
    meta: new1GLKyv1EeCMeta || {},
    component: () => import("/vercel/path0/pages/devices/new.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: ["/org"],
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "org-switch",
    path: "/org-switch",
    meta: org_45switchRG90OKP7p7Meta || {},
    component: () => import("/vercel/path0/pages/org-switch.vue").then(m => m.default || m)
  },
  {
    name: "org-switcher",
    path: "/org-switcher",
    meta: org_45switcherevb9ewn2MUMeta || {},
    component: () => import("/vercel/path0/pages/org-switcher.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-dashboard",
    path: "/orgs/:slug()/dashboard",
    meta: indexWWeg0dKyzZMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-dashboard-leads",
    path: "/orgs/:slug()/dashboard/leads",
    meta: leadscg99ApG3xBMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/dashboard/leads.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-dashboard-profile",
    path: "/orgs/:slug()/dashboard/profile",
    meta: profilezCybHZkHvKMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-dashboard-templates",
    path: "/orgs/:slug()/dashboard/templates",
    meta: templatesiPW5AcjXOgMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/dashboard/templates.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug",
    path: "/orgs/:slug()",
    meta: indexswt0H8YI0JMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-manage-org",
    path: "/orgs/:slug()/manage/org",
    meta: orgBOWADsw39yMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/manage/org.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-manage-profile",
    path: "/orgs/:slug()/manage/profile",
    meta: profilebrxzV1Rd3lMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/manage/profile.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-manage-team",
    path: "/orgs/:slug()/manage/team",
    meta: teamBplYnawIhbMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/manage/team.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-org-flows-id",
    path: "/orgs/:slug()/org-flows/:id()",
    meta: indexU2RJYWxNFAMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/org-flows/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-org-flows-id-setup",
    path: "/orgs/:slug()/org-flows/:id()/setup",
    meta: setuphwcbWVvJBkMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/org-flows/[id]/setup.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-score",
    path: "/orgs/:slug()/score",
    component: () => import("/vercel/path0/pages/orgs/[slug]/score/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-team",
    path: "/orgs/:slug()/team",
    component: () => import("/vercel/path0/pages/orgs/[slug]/team/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-user-flows-id",
    path: "/orgs/:slug()/user-flows/:id()",
    meta: indexLmDWppGi1FMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/user-flows/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-user-flows-id-setup",
    path: "/orgs/:slug()/user-flows/:id()/setup",
    meta: setupvHYvopGzWMMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/user-flows/[id]/setup.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug-welcome",
    path: "/orgs/:slug()/welcome",
    meta: welcomeoO631poOnTMeta || {},
    component: () => import("/vercel/path0/pages/orgs/[slug]/welcome.vue").then(m => m.default || m)
  },
  {
    name: "policies-id",
    path: "/policies/:id()",
    meta: _91id_93oZkwtOEN5jMeta || {},
    alias: ["/security-policy/:id"],
    component: () => import("/vercel/path0/pages/policies/[id].vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "security-assessment",
    path: "/security-assessment",
    meta: indexJJ7LgVnsavMeta || {},
    component: () => import("/vercel/path0/pages/security-assessment/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-connect",
    path: "/signup/connect",
    meta: connectP5lMxj21ctMeta || {},
    component: () => import("/vercel/path0/pages/signup/connect.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexLAWGSf2MvbMeta || {},
    component: () => import("/vercel/path0/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    alias: ["/create-org"],
    component: () => import("/vercel/path0/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/user-flows/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/org-flows/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  }
]