<template>
  <AtroCard
    class="w-[726px] border-2 border-dashed border-atro-purple !bg-atro-purple/5 !p-10 text-center"
    size="full"
  >
    <AtroHeading
      semibold
      class="mb-2 text-atro-slate-purple"
      text="Here’s a look at your Security foundation"
    />
    <AtroParagraph
      class="text-atro-gray"
      size="sm"
      text="Based on your assessment here’s the security measure you have in place vs. what we recommend"
    />

    <AtroContent v-if="hasSecurityMeasuresNeeded" class="mt-4" justify="center">
      <AtroSpan
        semibold
        class="rounded-lg bg-atro-red px-3 py-1 text-white"
        size="lg"
        :text="`${securityMeasuresNeeded.length} Security Measures Needed`"
      />
    </AtroContent>

    <AtroContent class="mt-6 gap-2">
      <AtroContent
        v-for="securityMeasure in securityMeasures"
        class="relative h-[101px] w-[120px] rounded-lg border bg-white p-2"
        justify="center"
        items="center"
        direction="col"
        wrap="nowrap"
        :key="securityMeasure.title"
      >
        <AtroIcon class="size-8" :name="securityMeasure.icon" />
        <AtroSpan
          class="mt-2 !leading-4 text-atro-gray-2"
          size="xs"
          :text="securityMeasure.title"
        />
        <AtroIcon
          :class="[
            'absolute right-2 top-2 size-4',
            {
              'text-atro-green': securityMeasure.status === 'ok',
              'text-atro-red': securityMeasure.status === 'warning',
            },
          ]"
          :name="
            securityMeasure.status === 'ok' ? 'circle-check' : 'circle-xmark'
          "
        />
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
export interface Props {
  securityMeasures: {
    icon: IconName
    status: 'ok' | 'warning'
    title: string
  }[]
}

withDefaults(defineProps<Props>(), {  })

const securityMeasuresNeeded = computed(() =>
  __props.securityMeasures.filter((securityMeasure) => securityMeasure.status !== 'ok'),
)
const hasSecurityMeasuresNeeded = computed(
  () => securityMeasuresNeeded.value.length > 0,
)
</script>
