<template>
  <div
    v-auto-animate
    class="grid w-full grid-cols-flow-card justify-center gap-2 sm:justify-start sm:gap-4"
  >
    <FlowCard
      v-for="flow in flows"
      :flow
      :view
      :key="flow.id"
      @click="(e) => onFlowCardClick(e, flow)"
      @keydown.space.prevent="(e) => onFlowCardClick(e, flow)"
    />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  flows: Flow[]
  view: 'admin' | 'marketplace' | 'member'
}

withDefaults(defineProps<Props>(), {  })

const { startOpenTransition } = useFlowCardTransition(true)

function onFlowCardClick(e: Event, flow: Flow) {
  if (flow.status === 'coming_soon' || __props.view === 'marketplace') return
  let targetElement = e.target as Element
  if (!targetElement.classList.contains('flow-card')) {
    targetElement = targetElement.closest('.flow-card') as Element
  }
  if (targetElement) {
    const cardRect = targetElement.getBoundingClientRect()
    startOpenTransition(flow, cardRect, flow.status === 'started')
  }
}
</script>
