<template>
  <AtroContent
    v-auto-animate
    class="h-full w-full flex-1"
    wrap="nowrap"
    :direction="$isMobile.value ? 'col' : 'row'"
  >
    <AtroContent class="w-full flex-1" direction="col" wrap="nowrap">
      <Editor
        :autofocus
        :can-reset
        :reset-action-label
        v-model="modelValue"
        @on-editor="setEditor"
        @reset="emit('action', { type: 'reset_policy' })"
        @smart-node-click="onSmartNodeClick"
      >
        <template #below-toolbar v-if="$isMobile.value">
          <AtroContent
            class="mt-2 max-h-[150px] w-full overflow-auto pb-4 shadow-[0px_8px_8px_-7px_rgba(0,0,0,0.1)]"
            direction="col"
            items="center"
            wrap="nowrap"
          >
            <AtroSpan semibold class="text-atro-slate-purple"
              >Smart Fields</AtroSpan
            >
            <AtroContent
              class="w-full overflow-auto"
              direction="col"
              wrap="nowrap"
            >
              <Form
                v-model="smartFields"
                :key="Object.keys(smartFields).length"
                :actions="false"
                @submit="() => false"
              >
                <FormKit
                  v-for="(val, key) of smartFields"
                  :key="key"
                  :name="key"
                  type="text"
                  :label="key"
                  @focus="onFocus(key)"
                  @blur="onBlur(key)"
                />
              </Form>
            </AtroContent>
          </AtroContent>
        </template>
      </Editor>
    </AtroContent>
    <AtroContent
      v-if="hasSmartFields && !$isMobile.value"
      class="sticky -top-12 z-10 ml-8 max-h-[calc(100svh-14.5rem)] self-stretch overflow-auto border-l border-t-0 bg-white pl-8 pt-4"
      direction="col"
      wrap="nowrap"
    >
      <AtroContent class="mb-6" direction="col">
        <AtroHeading
          semibold
          class="text-atro-dark-purple"
          size="xs"
          text="Smart Fields"
        />
        <AtroSpan
          class="text-atro-gray"
          text="All fields must be filled out to continue"
        />
      </AtroContent>
      <AtroContent class="w-full" direction="col" wrap="nowrap">
        <Form
          v-model="smartFields"
          input-width="full"
          :key="Object.keys(smartFields).length"
          :actions="false"
          @submit="() => false"
        >
          <FormKit
            v-for="(val, key) of smartFields"
            :key="key"
            type="text"
            :name="key"
            :label="key"
            @focus="onFocus(key)"
            @blur="onBlur(key)"
          />
        </Form>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { toCamelCase } from 'js-convert-case'
import type { Editor } from '@tiptap/vue-3'

type SmartFields = Record<string, string>

export interface Props {
  modelValue: string

  autofocus?: boolean
  canReset?: boolean
  fieldOverrides?: SmartFields
  resetActionLabel?: string
}

withDefaults(defineProps<Props>(), { canReset: true,fieldOverrides: {},resetActionLabel: 'Reset', })
const emit = defineEmits<{
  action: [action: FlowAction]
  scrollTo: [to: { x?: number; y?: number }]
  validityChange: [valid: boolean]
}>()
const modelValue = defineModel<Props['modelValue']>({ default: '' })

let editorRef: Ref<Editor>
const focusedSmartFieldKey = ref()
const inputFocusMadeFromNode = ref(false)

const hasSmartFields = computed(
  () => Object.keys(smartFields.value || {}).length,
)
const smartFields = computed<SmartFields>({
  get() {
    const fields = {} as SmartFields
    if (document) {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(modelValue.value, 'text/html')
      const smartTags = htmlDoc.querySelectorAll('[data-smart]')

      smartTags.forEach((node) => {
        const smartFieldName = node.getAttribute('data-smart') as string
        const initial = node.getAttribute('data-initial') as string
        const fieldOverride = __props.fieldOverrides[toCamelCase(smartFieldName)]
        const currentContent = node.innerHTML
        if (typeof fields[smartFieldName] === 'undefined') {
          fields[smartFieldName] =
            currentContent === initial
              ? ''
              : currentContent || fieldOverride || ''
        }
      })
      emit(
        'validityChange',
        Object.values(fields).every((field) => !!field?.trim().length),
      )
    }

    return fields
  },
  async set(fields) {
    if (document) {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(modelValue.value, 'text/html')
      const smartTags = htmlDoc.querySelectorAll('[data-smart]')

      smartTags.forEach((node) => {
        const smartFieldName = node.getAttribute('data-smart') as string
        const replacement =
          fields[smartFieldName] || node.getAttribute('data-initial') || ''
        node.innerHTML = replacement
        if (smartFieldName === focusedSmartFieldKey.value) {
          node.setAttribute('data-focused', 'true')
        }
      })
      // we have to let the underlying editor's model settle on initial render before updating
      await nextTick()
      modelValue.value = htmlDoc.body.innerHTML
    }
  },
})

function onBlur(key: string) {
  focusedSmartFieldKey.value = null
  editorRef.value.view.dom
    .querySelectorAll(`[data-focused]`)
    .forEach((el) => el.removeAttribute('data-focused'))
}

function onFocus(key: string) {
  focusedSmartFieldKey.value = key
  const els = editorRef.value.view.dom.querySelectorAll(`[data-smart="${key}"]`)
  if (els.length && !inputFocusMadeFromNode.value) {
    emit('scrollTo', { y: els[0].getBoundingClientRect().top })
  }
  els.forEach((el) => el.setAttribute('data-focused', 'true'))
  inputFocusMadeFromNode.value = false
}

function onSmartNodeClick(key: string) {
  inputFocusMadeFromNode.value = true
  document.querySelector(`input[name='${key}']`)?.focus()
}

function setEditor(editor: Ref<Editor>) {
  editorRef = editor
}
</script>
