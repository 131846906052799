<template>
  <SubscribeBanner v-if="showSubscribeBanner" />

  <!-- <Transition name="fade">
    <OnboardingWidget
      v-if="showOnboardingWidget"
      :class="[
        'fixed z-50 transition-all duration-500',
        {
          'top-3 sm:top-6': !showSubscribeBanner,
          'right-4 sm:right-6': !isViewingFlow,
          'right-4 sm:right-14 detail:right-6': isViewingFlow,
          'top-5 sm:top-6': !showSubscribeBanner && isViewingFlow,
          'top-[60px] sm:top-18': showSubscribeBanner && !isViewingFlow,
          'top-[68px] sm:top-18': showSubscribeBanner && isViewingFlow,
        },
      ]"
    />
  </Transition> -->

  <NuxtLayout id="app">
    <NuxtPage />
  </NuxtLayout>

  <!-- CLIENT ONLY COMPONENTS -->
  <ClientOnly>
    <!-- GLOBAL COMPONENTS -->
    <MobileNavDrawer v-if="includeMobileNavDrawer" />
    <ModalConfirmNavigation />
    <!-- GENERAL PURPOSE MODAL OUTLET -->
    <Modal />

    <!-- CARD OPEN ANIMATION -->
    <Transition
      enter-active-class="transition-opacity duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <template v-if="flowCardTransitionConfig">
        <FlowCardTransitional v-bind="flowCardTransitionConfig" />
      </template>
    </Transition>

    <!-- TOAST CONTAINER -->
    <Toaster offset="0px" position="top-center" />
  </ClientOnly>
</template>

<script setup lang="ts">
useElectronApi()
const globalState = useGlobalState()
const isMobile = useIsMobile()
const { isWebContext } = useClientContext()
const { showSubscribeBanner } = useCurrentSubscription()
const { hasJoined } = useCurrentUser()
const { flowCardTransitionConfig } = useFlowCardTransition()
const { pageIsDirty } = usePageDirtyTracking()

const includeMobileNavDrawer = computed(
  () => isMobile.value && hasJoined.value && isWebContext.value,
)

useHead({
  bodyAttrs: {
    class: computed(() => {
      const classes = []
      if (
        globalState.value.appBannerShown &&
        !globalState.value.upgradeModalShown
      )
        classes.push('has-app-banner')
      if (globalState.value.bottomToolbarActive) {
        classes.push('has-bottom-toolbar')
      }
      return classes
    }),
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Atro` : 'Atro'
  },
})

onMounted(() => {
  window.onbeforeunload = () => {
    if (pageIsDirty.value) {
      return 'You have attempted to leave this page. Are you sure?'
    }
  }
})
</script>
