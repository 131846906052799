<template>
  <component
    v-if="isShown"
    :data
    :flow
    :parent-org
    :users
    :view-as
    v-bind="componentProps"
    v-model="model"
    :data-type="type"
    :data-anchor="anchor ? `anchor-${anchor}` : null"
    :is="Component"
    :score="flow?.score"
  />
</template>

<script setup lang="ts">
import dot from 'dot-object'
import { toCamelCase } from 'js-convert-case'

export interface Props {
  type: string

  anchor?: string
  data?: Record<string, any>
  dataMappings?: string[][]
  flow?: Flow
  modelValue?: string
  name?: string
  parentOrg?: ParentOrg
  props?: any
  users?: User[] | null
  viewAs?: View
}

const props = defineProps<Props>()

const Component = useComponentForFlow(props.type)

const isWritable = ref(true)

const linkedDataMappings = computed(() => {
  return Array.isArray(props.dataMappings)
    ? props.dataMappings.reduce(
        (mappings, current) => {
          mappings[current[1]] = dot.pick(current[0], props)
          return mappings
        },
        {} as Record<string, any>,
      )
    : {}
})
const componentProps = computed(() => {
  const src = { ...props.props }
  Object.entries(linkedDataMappings.value).forEach(([key, val]) => {
    dot.set(key, val, src)
  })
  return src
})
const dataKey = computed(() => toCamelCase(props.modelValue || ''))
const isShown = computed(() => {
  if (Array.isArray(componentProps.value?.show)) {
    return componentProps.value?.show.every(
      (showPropName: string) =>
        typeof showPropName === 'string' && dot.pick(showPropName, props),
    )
  }
  if (typeof componentProps.value?.show === 'string') {
    return dot.pick(componentProps.value.show, props)
  } else if (typeof componentProps.value?.show === 'boolean') {
    return componentProps.value?.show
  } else {
    return true
  }
})
const model = computed({
  get() {
    return dataKey.value
      ? props.data?.[dataKey.value]
      : componentProps.value.modelValue
  },
  set(value) {
    if (props.data && dataKey.value && isWritable.value) {
      props.data[dataKey.value] = value
    }
  },
})

// we have to set isWritable as the component is unmounting
// so we don't clobber model state with undefined values
onBeforeUnmount(() => {
  isWritable.value = false
})
</script>
