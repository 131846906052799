<template>
  <AtroContent
    class="h-full w-full"
    direction="col"
    items="center"
    wrap="nowrap"
  >
    <AtroContent
      :class="[
        'w-full p-4 pb-0 sm:p-12 sm:pb-0',
        { 'border-b sm:pb-6': hasAnchors },
      ]"
    >
      <AtroContent class="w-full">
        <AtroContent class="w-full" items="center" justify="between">
          <AtroContent class="flex-1">
            <AtroButton
              icon-left="chevron-left"
              type="fill"
              size="md"
              @click="openMarketplace()"
              >Back to All</AtroButton
            >
          </AtroContent>
          <FlowMarketplaceAnchors
            v-if="hasAnchors && !$isMobile.value"
            class="flex-1 px-4"
            :anchors="anchors"
          />
          <AtroContent class="flex-1" justify="end">
            <AtroButton
              icon-right="link"
              text="Share"
              type="flat"
              @click="onShare"
            />
          </AtroContent>
        </AtroContent>
      </AtroContent>
      <FlowMarketplaceAnchors
        v-if="hasAnchors && $isMobile.value"
        :anchors
        class="mt-4 flex-1 px-4"
      />
    </AtroContent>

    <AtroContent shrink class="relative h-full w-full">
      <FlowPageController
        :flow
        :page
        page-type="modal"
        width="lg"
        :class="['scroller__warm', { '!pt-2': !hasAnchors }]"
        @action="onAction"
        @submit="onSubmit"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  flow: Flow
}

const props = defineProps<Props>()

const { openMarketplace } = useMarketplace()
const { pages } = usePages(props.flow, 'aboutPages')
const { copyUrl } = useShareUrl()

const page = computed(() => pages.value[0])

const anchors = computed(() =>
  (page.value?.mainComponents || [])
    .map((component) => component.anchor)
    .filter(Boolean),
)
const hasAnchors = computed(() => !!anchors.value.length)

// TODO: fill in stub
function onAction() {}

// TODO: fill in stub
function onSubmit() {}

function onShare() {
  copyUrl('Share link copied!')
}
</script>
