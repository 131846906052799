<template>
  <MainContentCard :flat="pageType === 'modal'">
    <!-- Main Column -->
    <MainContent
      :class="{
        'after:h-10 after:w-full after:shrink-0': pageType === 'modal',
        'h-full': page.centered,
      }"
      items="center"
      :justify="page.centered ? 'center' : 'start'"
      :key="page.slug"
      :width="pageWidth"
    >
      <!-- MOBILE SCORE -->
      <AtroContent
        v-if="page.showScore && $isMobile.value"
        class="mb-4 w-full"
        items="center"
        justify="center"
      >
        <AtroScoreGauge size="sm" :score="flow.score" />
      </AtroContent>

      <!-- MAIN COMPONENTS -->
      <FlowComponent
        v-for="config in mainComponents"
        :flow
        :parent-org
        :users
        :view-as
        v-bind="config"
        :key="config.name"
        :data="flow.data"
        @action="onAction"
        @refresh="emit('refreshData')"
        @submit="onSubmit"
        @scroll-to="onScrollTo"
        @validity-change="(valid: boolean) => onValidityChange(valid)"
      />
    </MainContent>

    <!-- Aside Column -->
    <AsideContent v-if="showAside" class="justify-between">
      <ScoreCard v-if="page.showScore" type="flat" :score="flow.score" />
      <AtroContent
        v-auto-animate
        class="w-full space-y-4"
        direction="col"
        wrap="nowrap"
      >
        <FlowComponent
          v-for="config in asideComponents"
          :flow
          :parent-org
          :users
          :view-as
          v-bind="config"
          :key="config.name"
          :data="flow.data"
          @action="onAction"
          @refresh="emit('refreshData')"
          @validity-change="(valid: boolean) => onValidityChange(valid)"
        />
      </AtroContent>
    </AsideContent>

    <ModalMobileFlowAside
      v-if="showMobileAside"
      :flow
      :users
      :components="asideComponents"
      :is-open="mobileAsideModalOpen"
      @action="onAction"
      @refresh="emit('refreshData')"
      @close="mobileAsideModalOpen = false"
    />
  </MainContentCard>
</template>

<script setup lang="ts">
export interface Props {
  flow: Flow
  page: Page
  pageType: 'main' | 'modal'

  parentOrg?: ParentOrg
  showScore?: boolean
  setup?: boolean
  users?: User[] | null
  viewAs?: View
  width?: 'md' | 'lg' | 'full'
}

const FULL_WIDTH_COMPONENTS = ['editor']

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [page: Page, action: FlowAction]
  refreshData: []
  submit: [data: any]
}>()

const isMobile = useIsMobile()
const { mobileAsideModalOpen, pageFocus, scrollingEl, valid } = usePageState()
const { y: scrollY } = useScroll(scrollingEl, { behavior: 'smooth' })

const dataPoll = ref()

const asideComponents = computed(() => props.page?.asideComponents || [])
const mainComponents = computed(() => props.page?.mainComponents || [])
const hasAsideComponents = computed(() => !!asideComponents.value.length)
const isDataPending = computed(() => {
  return !!mainComponents.value.find(
    (componentConfig) =>
      componentConfig.type === 'status_card' &&
      componentConfig.props.status === 'pending',
  )
})
const currentPageIsFullscreen = computed(() => {
  return (
    mainComponents.value.some((component) =>
      FULL_WIDTH_COMPONENTS.includes(component.type),
    ) || props.page.fullWidth
  )
})
const pageWidth = computed(() => {
  return currentPageIsFullscreen.value ? 'full' : props.width || 'md'
})
const showAside = computed(() => hasAsideComponents.value && !isMobile.value)
const showMobileAside = computed(
  () => hasAsideComponents.value && isMobile.value,
)

function onAction(action: FlowAction) {
  emit('action', props.page, action)
}

function onScrollTo({ y = 0 }: { x?: number; y?: number }) {
  const currentScroll = scrollingEl.value?.scrollTop || 0
  scrollY.value = y + currentScroll - 420
}

function onSubmit(data: any) {
  emit('submit', data)
}

function onValidityChange(validity: boolean) {
  if (pageFocus.value === props.pageType) valid.value = validity
}

watch(
  isDataPending,
  (updated) => {
    clearInterval(dataPoll.value)
    if (updated && import.meta.client) {
      dataPoll.value = setInterval(() => emit('refreshData'), 1000)
    }
  },
  { immediate: true },
)
</script>
