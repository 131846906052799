<template>
  <AtroContent
    v-auto-animate
    id="flow-main-content-card"
    wrap="nowrap"
    :class="[
      'scroller scroller__inset !static h-full w-full flex-1 p-0 sm:p-12',
      {
        '!pb-0': flat,
        'rounded-3xl bg-white shadow-card': !flat,
        'justify-start': justify === 'start',
        'justify-center': justify === 'center',
        'justify-end': justify === 'end',
      },
    ]"
    :ref="setScrollingRef"
  >
    <slot />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  flat?: boolean
  justify?: 'start' | 'center' | 'end'
}

withDefaults(defineProps<Props>(), { justify: 'center', })

const { scrollingEl } = usePageState()

function setScrollingRef(cmp: any) {
  if (cmp) {
    scrollingEl.value = cmp.$el ? cmp.$el : cmp
  }
}
</script>
