<template>
  <AtroContent
    :class="[
      'w-full rounded-b-2xl border-2 border-t-0 border-atro-warm-gray-2 p-0',
      { 'bg-white': bg === 'white', 'bg-atro-warm-gray-2': bg === 'gray' },
    ]"
    direction="col"
    wrap="nowrap"
  >
    <!-- ITEM CUSTOM COMPONENT -->
    <AtroContent v-if="ItemComponent" class="w-full p-3 sm:p-6">
      <component
        :is="ItemComponent"
        v-bind="itemComponentProps"
        @action="onAction"
      />
    </AtroContent>
    <template v-else>
      <!-- ITEM TEXT BODY -->
      <AtroContent
        v-if="itemSubtext"
        class="w-full bg-atro-warm-gray-2 p-3 !pt-2 sm:p-6"
        direction="col"
      >
        <AtroMarkdown
          prose
          class="text-atro-gray-1"
          :markdown="itemSubtext"
          @action="onAction"
        />
        <!-- ITEM ACTION BUTTON -->
        <AtroButton
          v-if="itemAction"
          class="mt-4"
          :disabled="itemActionDisabled"
          :icon-right="itemActionIcon"
          :text="itemActionLabel"
          :type="itemActionButtonType || 'flat'"
          @click="onAction(itemAction)"
        />
        <!-- FIX ACTION -->
        <AtroContent
          v-if="itemFixAction?.status === 'active'"
          class="mt-4 w-full rounded-2xl bg-atro-purple/5 p-6"
          items="center"
          justify="between"
          wrap="nowrap"
        >
          <AtroContent shrink class="w-full space-y-1 pr-6" direction="col">
            <AtroSpan
              semibold
              class="text-atro-slate-purple transition-colors group-hover:text-atro-purple group-active:text-atro-purple-75"
              :text="itemFixAction.title"
            />
            <AtroParagraph
              v-if="itemFixAction.subtitle && !$isMobile.value"
              class="text-atro-gray"
              size="sm"
              :text="itemFixAction.subtitle"
            />
          </AtroContent>
          <AtroButton icon-right="chevron-right" text="Fix This" />
        </AtroContent>
      </AtroContent>

      <!-- ITEM SUBITEMS -->
      <AtroContent v-if="subitems?.length" class="w-full p-3 sm:p-6 sm:pt-4">
        <AtroContent
          v-if="hasSubitemsHeader"
          class="w-full"
          justify="between"
          items="center"
        >
          <AtroMarkdown
            v-if="subitemsHeading"
            prose
            class="text-xs font-semibold text-atro-gray-1"
            :markdown="subitemsHeading"
            @action="onAction"
          />
          <AtroButton
            v-if="subitemsAction"
            size="sm"
            :disabled="subitemsActionDisabled"
            :type="subItemsButtonType || 'flat'"
            :text="subitemsActionLabel || staticItemActionLabel"
            @click="onAction(subitemsAction)"
          />
        </AtroContent>

        <FlowAtomListItemSubitems :subitems class="mt-2" @action="onAction" />
      </AtroContent>
    </template>
  </AtroContent>
</template>

<script setup lang="ts">
import type { ButtonProps } from '@atro/components'

export interface Props {
  bg?: 'gray' | 'white'
  itemAction?: FlowAction
  itemActionButtonType?: ButtonProps['type']
  itemActionDisabled?: boolean
  itemActionIcon?: IconName
  itemActionLabel?: string
  itemComponentName?: string
  itemComponentProps?: Record<string, unknown>
  itemFixAction?: Action
  itemSubtext?: string
  subitems?: FlowAtomListItemSubitem[]
  subitemsAction?: FlowAction
  subItemsButtonType?: ButtonProps['type']
  subitemsActionDisabled?: boolean
  subitemsActionLabel?: string
  subitemsHeading?: string
}

withDefaults(defineProps<Props>(), { bg: 'white', })
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const ItemComponent = useComponentForFlow(__props.itemComponentName)
const { actionLabel: staticItemActionLabel } = useFlowActionMeta(__props.subitemsAction)

const hasSubitems = computed(() => (__props.subitems?.length || 0) > 0)
const hasSubitemsHeader = computed(
  () => hasSubitems.value && (!!__props.subitemsAction || !!__props.subitemsHeading),
)

function onAction(action: FlowAction) {
  emit('action', action)
}
</script>
