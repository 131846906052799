<template>
  <AtroContent
    items="center"
    wrap="nowrap"
    :class="[
      'mb-4 w-full rounded-2xl px-8 py-5',
      {
        'bg-atro-red/25': status === 'error',
        'bg-atro-green/15': status === 'success',
        'bg-primary/25': ['neutral', 'pending'].includes(status),
      },
    ]"
  >
    <AtroIconWrap
      v-if="iconName"
      circle
      type="light"
      :class="['!border-0', iconColorClass]"
      :name="iconName"
      :size="iconSize"
    />
    <AtroContent
      shrink
      class="ml-4 w-full"
      items="center"
      justify="between"
      wrap="nowrap"
    >
      <AtroContent
        shrink
        direction="col"
        :class="{
          'text-atro-red': status === 'error',
          'text-atro-dark-purple-85': status === 'neutral',
          'text-atro-green': status === 'success',
        }"
      >
        <AtroSpan semibold size="lg" :text="title" />
        <AtroSpan v-if="subtitle" class="mt-2" :text="subtitle" />
      </AtroContent>

      <FlowAtomButton
        v-if="button"
        class="ml-10"
        type="light"
        :bordered="false"
        v-bind="button"
        @action="onAction"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import type { IconWrapProps } from '@atro/components'
import type { Props as ButtonProps } from '@/components/flow/atom/FlowAtomButton.vue'

export interface Props {
  title: string

  button?: ButtonProps
  icon?: IconName
  iconSize?: IconWrapProps['size']
  status?: 'error' | 'neutral' | 'pending' | 'success'
  subtitle?: string
}

withDefaults(defineProps<Props>(), { iconSize: 'md',status: 'neutral', })

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const onAction = (action: FlowAction) => {
  emit('action', action)
}

const iconColorClass = computed(() => {
  switch (__props.status) {
    case 'error':
      return 'text-atro-red'
    case 'neutral':
      return 'text-atro-dark-purple-85'
    case 'success':
      return 'text-atro-green'
  }
})

const iconName = computed<IconName>(() => {
  if (__props.icon) return __props.icon
  switch (__props.status) {
    case 'error':
      return 'triangle-exclamation'
    case 'neutral':
      return 'magnifying-glass'
    case 'pending':
      return 'loading'
    case 'success':
      return 'check'
  }
})
</script>
