export default function useFlowActionMeta(action?: FlowAction) {
  const actionLabel = computed(() => {
    switch (action?.type) {
      case 'notify':
        return 'Send alert'
      case 'notify_many':
        return 'Alert all'
      default:
        return ''
    }
  })

  return { actionLabel }
}
