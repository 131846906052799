<template>
  <AtroExpandableContent
    v-model="expanded"
    class="w-full rounded-2xl bg-atro-warm-gray-2 p-4 pr-0"
    direction="col"
    wrap="nowrap"
  >
    <template #default="{ toggleExpand }">
      <AtroContent
        class="w-full cursor-pointer pr-[28px]"
        justify="between"
        wrap="nowrap"
        @click="toggleExpand"
      >
        <AtroContent class="space-x-2" items="center" justify="center">
          <AtroExpandToggle v-model="expanded" />
          <AtroHeading
            semibold
            class="text-atro-gray-2"
            size="xs"
            :text="group.name"
          />
          <AtroBadge
            size="sm"
            type="neutral"
            :label="`${group.users.length} ${group.users.length === 1 ? 'Member' : 'Members'}`"
          />
        </AtroContent>
        <AtroSwitch v-model="groupEnabled" type="switch" @click.stop />
      </AtroContent>
    </template>

    <template #expanded-content>
      <AtroContent
        class="mt-4 w-full space-y-2 pr-4"
        direction="col"
        wrap="nowrap"
      >
        <UserIncludedItem
          v-for="user in group.users"
          :user
          :key="user.id"
          :included="!model?.includes(user.id)"
          @exclude-user="excludeUser(user)"
          @include-user="includeUser(user)"
        />
      </AtroContent>
    </template>
  </AtroExpandableContent>
</template>

<script setup lang="ts">
export interface Props {
  group: Group
  modelValue: string[]
}

withDefaults(defineProps<Props>(), {  })
const model = defineModel<Props['modelValue']>({ default: [] })

const groupEnabled = computed({
  get() {
    return !__props.group.users.every((user) => model.value.includes(user.id))
  },
  set(value: boolean) {
    if (value) {
      includeUser(__props.group.users)
      expanded.value = true
    } else {
      excludeUser(__props.group.users)
      expanded.value = false
    }
  },
})
const expanded = ref(groupEnabled.value)

function excludeUser(user: User | User[]) {
  const ids = Array.isArray(user) ? user.map((u) => u.id) : [user.id]
  model.value = Array.from(new Set([...model.value, ...ids]))
}
function includeUser(user: User | User[]) {
  const ids = Array.isArray(user) ? user.map((u) => u.id) : [user.id]
  model.value = model.value.filter((userId) => !ids.includes(userId))
}
</script>
