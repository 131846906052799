<template>
  <AtroContent direction="col" items="center" justify="center">
    <AtroSpan
      semibold
      class="mb-2 text-atro-slate-purple"
      size="sm"
      :text="title"
    />
    <NuxtImg height="83" width="106" :src="source" />
    <AtroSpan
      semibold
      size="lg"
      :class="[
        'capitalize',
        {
          'text-atro-green': riskLevel === 'low',
          'text-atro-yellow': riskLevel === 'medium',
          'text-atro-red': riskLevel === 'high',
        },
      ]"
      :text="riskLevel"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  riskLevel: RiskLevel

  title?: string
}

withDefaults(defineProps<Props>(), { title: 'Your Likelihood', })

const source = computed(() => `/gauge-${__props.riskLevel}-risk.svg`)
</script>
