<template>
  <AtroCard
    class="self-stretch overflow-hidden !rounded-2xl border-2 !p-0 sm:flex-1"
  >
    <AtroContent
      items="center"
      justify="center"
      :class="[
        'h-12 w-full px-6 py-2 sm:px-10',
        {
          'text-atro-gray-2': current,
          'text-white': !current,
          'bg-atro-warm-gray': planType === 'free',
          'banner-gradient': planType === 'pro',
        },
      ]"
    >
      <AtroSpan class="leading-4" size="xs" :text="headingText" />
    </AtroContent>

    <AtroContent
      class="w-full flex-1 bg-atro-warm-gray-3 p-4"
      direction="col"
      wrap="nowrap"
    >
      <AtroContent class="w-full px-4">
        <AtroHeading
          semibold
          class="text-atro-slate-purple"
          size="xxl"
          :text="planTitle"
        />
        <AtroContent class="mt-2 w-full" items="end">
          <AtroHeading semibold class="text-primary" size="xxl" :text="price" />
          <AtroSpan class="mb-2 ml-0.5 text-atro-gray" :text="perPeriodText" />
        </AtroContent>
      </AtroContent>

      <AtroDivider size="lg" />

      <AtroContent class="w-full px-4" direction="col" wrap="nowrap">
        <AtroSpan
          semibold
          class="text-atro-gray"
          :text="
            planType === 'pro'
              ? 'You get everything in Free, plus:'
              : 'What’s included'
          "
        />
        <SubscriptionFeatureList :plan-type />

        <AtroContent class="mt-6 w-full" justify="center">
          <AtroButton
            v-if="planType === 'pro'"
            class="banner-gradient"
            icon-right="rocket-launch"
            text="Upgrade to Pro"
            @click="emit('upgrade', planType)"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroCard>
</template>

<script setup lang="ts">
export interface Props {
  planType: SubscriptionPlanType

  annual?: boolean
  current?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  upgrade: [planType: string]
}>()

const proPerMonthCost = computed(() => (props.annual ? '$166' : '$299'))

const headingText = computed(() => {
  if (props.current) return 'Current plan'
  switch (props.planType) {
    case 'free':
      return ''
    case 'pro':
      return `Discounted from $449 to ${proPerMonthCost.value}/mo, for our founding customers. Limited-time offer.`
    default:
      break
  }
})

const planTitle = computed(() => {
  switch (props.planType) {
    case 'free':
      return 'Free'
    case 'pro':
      return 'Pro'
    default:
      break
  }
})

const price = computed(() => {
  switch (props.planType) {
    case 'free':
      return '$0'
    case 'pro':
      return proPerMonthCost.value
    default:
      break
  }
})

const perPeriodText = computed(() =>
  props.annual && props.planType !== 'free' ? '/mo (Billed annually)' : '/mo',
)
</script>
