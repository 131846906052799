<template>
  <AtroGridTable gap="sm" :data="userFlowReports" :columns="columnsConfig">
    <template #default="{ columnProps, items }">
      <UserOrgFlowReportTableRow
        v-for="userFlowReport in items"
        v-bind="columnProps"
        :user-flow-report
        :key="userFlowReport.orgFlowId"
        :flow="orgFlows.find((flow) => flow?.id === userFlowReport.orgFlowId)"
      />
    </template>
  </AtroGridTable>
</template>

<script setup lang="ts">
export interface Props {
  orgFlows: OrgFlow[]
  userFlowReports: UserFlowReport[]
}

withDefaults(defineProps<Props>(), {  })

const columnsConfig = computed<TableColumnConfig[]>(() => [
  {
    heading: {
      align: 'start',
      text: 'Tools',
    },
    minColumnWidth: 200,
    sortBy(userFlowReport: UserFlowReport) {
      return __props.orgFlows.find((flow) => flow?.id === userFlowReport.orgFlowId)
        ?.title
    },
  },
  {
    heading: {
      text: 'Alerts',
    },
    fixedWidth: true,
    minColumnWidth: 60,
    sortBy(userFlowReport: UserFlowReport) {
      return getUserFlowReportAlertCount(userFlowReport)
    },
    initialSortedBy: 'desc',
  },
  {
    heading: {
      text: 'Score',
    },
    fixedWidth: true,
    sortBy: 'score',
    minColumnWidth: 50,
  },
  {
    heading: {
      text: 'Status',
    },
    fixedWidth: true,
    minColumnWidth: 50,
  },
])
</script>
