<template>
  <AtroContent class="space-y-2 sm:space-x-6 sm:space-y-0" :justify="align">
    <AtroButton
      block-when-mobile
      class="sm:w-52"
      :pending="primaryPending"
      @click="onPrimaryClicked"
      >{{ primaryButtonText }}</AtroButton
    >

    <AtroButton
      block-when-mobile
      class="ring-2 ring-atro-warm-gray-1 sm:w-52"
      type="transparent"
      :disabled="showAdded"
      :pending="secondaryPending"
      :text="secondaryButtonText"
      @click="onSecondaryClicked"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import type { ContentProps } from '@atro/components'

export interface Props {
  flow: Flow

  align?: ContentProps['justify']
}

withDefaults(defineProps<Props>(), { align: 'center', })

const { mutate: createOrgFlow } = useMutation<OrgFlow>('createOrgFlow')
const toast = useToast()

const primaryPending = ref(false)
const secondaryPending = ref(false)

const showGetStarted = computed(() =>
  ['available', 'started'].includes(__props.flow.status),
)
const showAdded = computed(
  () => !['available', 'coming_soon'].includes(__props.flow.status),
)
const primaryButtonText = computed(() =>
  showGetStarted.value ? 'Get Started' : 'View Status',
)
const secondaryButtonText = computed(() =>
  showAdded.value ? 'Added to Dashboard' : 'Add to Dashboard',
)

async function onPrimaryClicked() {
  if (!showAdded.value) {
    primaryPending.value = true
    try {
      await onCreateOrgFlow(true)
    } catch (e) {
      primaryPending.value = false
      toast.error('An error occurred creating the app')
    }
  } else {
    if (showGetStarted.value) {
      navigateToWithOrg(`/org-flows/${__props.flow.id}/setup`)
    } else {
      navigateToWithOrg(`/org-flows/${__props.flow.id}`)
    }
  }
}

async function onSecondaryClicked() {
  secondaryPending.value = true
  try {
    await onCreateOrgFlow()
  } catch (e) {
    toast.error('An error occurred creating the app')
  }
  secondaryPending.value = false
}

async function onCreateOrgFlow(redirect?: boolean) {
  const data = await createOrgFlow({ flowId: __props.flow.id })
  refreshNuxtData()
  redirect && navigateToWithOrg(`/org-flows/${data.id}/setup`)
}
</script>
